var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExchangeCalcCont d-flex justify-center flex-wrap align-center"},[_c('div',{staticClass:"\n      ExchangeCont\n      pa-3\n      d-flex\n      justify-start\n      align-content-start\n      flex-wrap\n    "},[_vm._m(0),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" Property Damage: ")]),_c('div',{staticClass:"InputContainer"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.quotation.currency},on:{"blur":function($event){_vm.calculeUSD();
            _vm.setStateTIVNON('propertyDamage', this);
            _vm.checkInsuredColumn('propertyDamage');}},model:{value:(_vm.tivNon.propertyDamage),callback:function ($$v) {_vm.$set(_vm.tivNon, "propertyDamage", $$v)},expression:"tivNon.propertyDamage"}})],1)]),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" Business Interruption ")]),_c('div',{staticClass:"InputContainer"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.quotation.currency},on:{"blur":function($event){_vm.calculeUSD();
            _vm.setStateTIVNON('businessInterruption', this);
            _vm.checkInsuredColumn('businessInterruption');}},model:{value:(_vm.tivNon.businessInterruption),callback:function ($$v) {_vm.$set(_vm.tivNon, "businessInterruption", $$v)},expression:"tivNon.businessInterruption"}})],1)]),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" Stocks: ")]),_c('div',{staticClass:"InputContainer"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.quotation.currency},on:{"blur":function($event){_vm.calculeUSD();
            _vm.setStateTIVNON('stock', this);
            _vm.checkInsuredColumn('stock');}},model:{value:(_vm.tivNon.stock),callback:function ($$v) {_vm.$set(_vm.tivNon, "stock", $$v)},expression:"tivNon.stock"}})],1)]),_c('div',{staticClass:"ExpansionLineTop mt-2"}),_c('div',{staticClass:"InputContent d-flex justify-center align-center"},[_c('div',{staticClass:"Total"},[_vm._v(" Total: "),_c('b',[_vm._v(" "+_vm._s(_vm.tivNon.total)+" ")])])]),_c('div',{staticClass:"ExpansionLineTop"}),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" PML: ")]),_c('div',{staticClass:"InputContainer"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.quotation.currency},on:{"blur":function($event){_vm.calculeUSD();
            _vm.setStatePremiumNON('pml', this);
            _vm.checkPMLColumn('pml');},"change":function($event){_vm.value = $event}},model:{value:(_vm.premiumNon.pml),callback:function ($$v) {_vm.$set(_vm.premiumNon, "pml", $$v)},expression:"premiumNon.pml"}})],1)]),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" Rate: ")]),_c('div',{staticClass:"InputContainer"},[_c('v-text-field',{staticClass:"ml-3",attrs:{"type":"number","suffix":"%","error-messages":_vm.quotationValids('premiumNon', 'rate')},on:{"blur":function($event){_vm.sumDatapremiumOriginal();
            _vm.setStatePremiumNON('rate', this);
            _vm.checkPMLColumn('rate');}},model:{value:(_vm.premiumNon.rate),callback:function ($$v) {_vm.$set(_vm.premiumNon, "rate", $$v)},expression:"premiumNon.rate"}})],1)]),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput bold"},[_vm._v(" premiumNon: ")]),_c('div',{staticClass:"InputContainer"},[_c('b',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.premiumNon.totalPremium)+" ")])])])]),_c('div',{staticClass:"\n      ExchangeCont\n      pa-3\n      d-flex\n      justify-start\n      align-content-start\n      flex-wrap\n    "},[_vm._m(1),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" Property Damage: ")]),_c('div',{staticClass:"InputContainer"},[_c('currency-input',{attrs:{"value":_vm.tivNon.propertyDamageUSD,"options":_vm.currencyOptions,"suffix":"USD"},on:{"blur":function($event){_vm.calculeUSD();
            _vm.setStateTIVNON('propertyDamageUSD', this);
            _vm.checkInsuredColumn('propertyDamageUSD');},"change":function($event){_vm.value = $event}}})],1)]),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" Business Interruption ")]),_c('div',{staticClass:"InputContainer"},[_c('currency-input',{attrs:{"value":_vm.tivNon.businessInterruptionUSD,"options":_vm.currencyOptions,"suffix":"USD"},on:{"blur":function($event){_vm.calculeUSD();
            _vm.setStateTIVNON('businessInterruptionUSD', this);
            _vm.checkInsuredColumn('businessInterruptionUSD');},"change":function($event){_vm.value = $event}}})],1)]),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" Stocks: ")]),_c('div',{staticClass:"InputContainer"},[_c('currency-input',{attrs:{"value":_vm.tivNon.stockUSD,"options":_vm.currencyOptions,"suffix":"USD"},on:{"blur":function($event){_vm.calculeUSD();
            _vm.setStateTIVNON('stockUSD', this);
            _vm.checkInsuredColumn('stockUSD');},"change":function($event){_vm.value = $event}}})],1)]),_c('div',{staticClass:"ExpansionLineTop mt-2"}),_c('div',{staticClass:"InputContent d-flex justify-center align-center"},[_c('div',{staticClass:"Total"},[_vm._v(" Total: "),_c('b',[_vm._v(" "+_vm._s(_vm.tivNon.totalUSD)+" ")])])]),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" PML: ")]),_c('div',{staticClass:"InputContainer"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":"USD"},on:{"blur":function($event){_vm.calculeUSD();
            _vm.setStatePremiumNON('pmlUSD', this);
            _vm.checkPMLColumn('pmlUSD');},"change":function($event){_vm.value = $event}},model:{value:(_vm.premiumNon.pmlUSD),callback:function ($$v) {_vm.$set(_vm.premiumNon, "pmlUSD", $$v)},expression:"premiumNon.pmlUSD"}})],1)]),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput"},[_vm._v(" Rate: ")]),_c('div',{staticClass:"InputContainer"},[_c('v-text-field',{staticClass:"ml-3",attrs:{"type":"number","suffix":"%","error-messages":_vm.quotationValids('premiumNon', 'rateUSD')},on:{"blur":function($event){_vm.sumDatapremiumUSD();
            _vm.setStatePremiumNON('rateUSD', this);
            _vm.checkPMLColumn('rateUSD');},"change":function($event){_vm.value = $event}},model:{value:(_vm.premiumNon.rateUSD),callback:function ($$v) {_vm.$set(_vm.premiumNon, "rateUSD", $$v)},expression:"premiumNon.rateUSD"}})],1)]),_c('div',{staticClass:"InputContent d-flex justify-end align-center"},[_c('span',{staticClass:"LabelInput bold"},[_vm._v(" premiumNon: ")]),_c('div',{staticClass:"InputContainer"},[_c('b',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.premiumNon.totalPremiumPremiumUSD)+" ")])])])]),_c('div',{staticClass:"ExpansionLineTop blueLight mt-2"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n        TitleCont\n        d-flex\n        justify-center\n        align-content-center align-center\n      "},[_c('h5',[_vm._v("Total insured value - original currency")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n        TitleCont\n        d-flex\n        justify-center\n        align-content-center align-center\n      "},[_c('h5',[_vm._v("Total Insurable value - USD")])])}]

export { render, staticRenderFns }