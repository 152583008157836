<template>
  <v-expansion-panels class="ExpansionComponent ExpansionBordered mt-1">
    <v-expansion-panel>
      <!--TITULO DEL ACORDEON-->
      <v-expansion-panel-header @click="changeStateExpansive()" class="ExpansionTitle" expand-icon="">
        Quotation
        <div class="ExpansionState HideOnMovil">
          {{ stateExpansiveMessage }}
        </div>
        <template v-slot:actions>
          <v-icon class="iconExpand">
            {{ iconExpansive }}
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <!--CUERPO-->
      <v-expansion-panel-content>
        <div class="ExpandContent">
          <NonProportional />
          <Deductions />
          <ExchangeCalculator />
          <LossCurves />
          <Layers />
          <!--LINEA DIVISORIA-->
          <div class="ExpansionLineTop mt-2" />

          <ExposureCurves />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import NonProportional from '@/components/subscription/quotation/NonProportional.vue';
import ExchangeCalculator from '@/components/subscription/quotation/ExchangeCalculator.vue';
import Deductions from '@/components/subscription/quotation/Deductions.vue';
import Layers from '@/components/subscription/quotation/Layers.vue';
import LossCurves from '@/components/subscription/quotation/LossCurves.vue';
import ExposureCurves from '@/components/subscription/quotation/ExposureCurves.vue';

import { mapActions, mapMutations } from 'vuex';

//MIXINS
import { stateExpansiveManager } from '@/mixins/subscription.js';

export default {
  name: 'QuoNonProp',
  mixins: [stateExpansiveManager],
  components: {
    NonProportional,
    ExchangeCalculator,
    Deductions,
    Layers,
    LossCurves,
    ExposureCurves,
  },
  async beforeMount() {
    this.RESET_QUOTATION();
    await this.updateQuotationType(2);
  },
  methods: {
    ...mapActions(['updateQuotationType']),
    ...mapMutations(['RESET_QUOTATION']),
  },
};
</script>
<style lang="less" scoped>
//CONTENEDOR DE QUOTATION
@import '~@/assets/style/AccordionStyle.less';
.ExpansionLineTop {
  background: #a8bfd9 !important;
}
</style>
