<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <!--TITULO DEL ACORDEON-->
      <v-expansion-panel-header @click="changeSubExpansion" expand-icon="">
        <template v-slot:actions>
          <div
            class="
	    		ExpandMiniTitle
	    		d-flex justify-start align-center
	    		"
          >
            <v-icon class="iconExpand">
              {{ iconSubExpansion }}
            </v-icon>
            Excess of Loss Curves
          </div>
        </template>
      </v-expansion-panel-header>
      <!--CONTENIDO DEL ACORDEON-->
      <v-expansion-panel-content>
        <div class="ExpandContent">
          <!--EMPIEZA LA TABLA-->
          <div class="TableContent mt-5 d-flex justify-center align-start align-content-start flex-wrap">
            <!--CABECERA DE LA TABLA-->
            <div class="TableHead d-flex justify-end align-center">
              <div class="TableHeadSn d-flex justify-start align-center">
                <!--TITULOS-->
                <div class="TableHeadTitle">
                  1
                </div>

                <div class="TableHeadTitle">
                  2
                </div>

                <div class="TableHeadTitle">
                  3
                </div>

                <div class="TableHeadTitle bgTable">
                  4
                </div>

                <div class="TableHeadTitle bgTable">
                  5
                </div>

                <div class="TableHeadTitle bgTable">
                  6
                </div>

                <div class="TableHeadTitle">
                  7
                </div>
              </div>
            </div>

            <!--CONTENIDO DE LA TABLA-->
            <div class="TableBody d-flex">
              <!--BARRA IZQUIERDA DEL CUERPO-->
              <div class="TableSideCont d-flex justify-start align-start flex-wrap">
                <div class="SideLeftTitle">
                  <div class="text">
                    <b>Detachment Point:</b><br />
                    Credit Factor
                  </div>
                </div>
                <div class="SideLeftTitle">
                  <div class="text">
                    <b>Attachment Point:</b><br />
                    Credit Factor
                  </div>
                </div>
                <div class="SideLeftTitle">
                  <div class="text">
                    <b>XL Premium:</b><br />
                    (Technical, %)
                  </div>
                </div>
              </div>

              <!--VALORES DE LA TABLA-->
              <div class="TableValuesCont">
                <!--
	    						Detachment Point
	    				-->
                <div class="TableRowsCont">
                  <div class="TableRow">
                    <div class="data">{{ values.diagonalDet }} %</div>
                  </div>
                  <div class="TableRow">
                    <div class="data">{{ values.smallestRisksDet }}%</div>
                  </div>
                  <div class="TableRow">
                    <div class="data">{{ values.smallHousesDet }}%</div>
                  </div>
                  <div class="TableRow bgTable">
                    <div class="data">{{ values.unMayorDet }}%</div>
                  </div>
                  <div class="TableRow bgTable">
                    <div class="data">{{ values.dosMayorDet }}%</div>
                  </div>
                  <div class="TableRow bgTable">
                    <div class="data">{{ values.tresMayorDet }}%</div>
                  </div>
                  <div class="TableRow">
                    <div class="data">{{ values.greatDet }}%</div>
                  </div>
                </div>

                <!--
	    						Attachment Point:
	    				-->
                <div class="TableRowsCont">
                  <div class="TableRow">
                    <div class="data">{{ values.diagonalAtt }} %</div>
                  </div>
                  <div class="TableRow">
                    <div class="data">{{ values.smallestRisksAtt }}%</div>
                  </div>
                  <div class="TableRow">
                    <div class="data">{{ values.smallHousesAtt }}%</div>
                  </div>
                  <div class="TableRow bgTable">
                    <div class="data">{{ values.unMayorAtt }}%</div>
                  </div>
                  <div class="TableRow bgTable">
                    <div class="data">{{ values.dosMayorAtt }}%</div>
                  </div>
                  <div class="TableRow bgTable">
                    <div class="data">{{ values.tresMayorAtt }}%</div>
                  </div>
                  <div class="TableRow">
                    <div class="data">{{ values.greatAtt }}%</div>
                  </div>
                </div>

                <!--
	    						XL Premium: (Technical, %)
	    				-->
                <div class="TableRowsCont">
                  <div class="TableRow">
                    <div class="data">{{ values.diagonalDet - values.diagonalAtt }} %</div>
                  </div>
                  <div class="TableRow">
                    <div class="data">{{ values.minSmallestRisks }}%</div>
                  </div>
                  <div class="TableRow">
                    <div class="data">{{ values.minSmallHouses }}%</div>
                  </div>
                  <div class="TableRow bgTable">
                    <div class="data">{{ values.minUnMayor }}%</div>
                  </div>
                  <div class="TableRow bgTable">
                    <div class="data">{{ values.minDosMayor }}%</div>
                  </div>
                  <div class="TableRow bgTable">
                    <div class="data">{{ values.minTresMayor }}%</div>
                  </div>
                  <div class="TableRow">
                    <div class="data">{{ values.minGreat }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--LINEA DIVISORIA-->
          <div class="ExpansionLineTop mt-5"></div>

          <!--Offer-->
          <div class="TableTwoColumns justify-center align-start align-content-start d-flex">
            <div class="TableTwoSideCont d-flex justify-start align-start flex-wrap">
              <div class="SideLeftTwo">
                Offer
              </div>
            </div>

            <!--VALORES DE OFFER-->
            <div class="TableTwoValuesCont">
              <div class="TableTwoRowsCont">
                <div class="TableTwoText">
                  <div class="data">
                    0,0000
                  </div>
                </div>
                <div class="TableTwoText">
                  <div class="data">
                    0,0000
                  </div>
                </div>
                <div class="TableTwoText">
                  <div class="data">
                    0,0000
                  </div>
                </div>
                <div class="TableTwoText">
                  0,0000
                </div>
                <div class="TableTwoText">
                  <div class="data">
                    0,0000
                  </div>
                </div>
                <div class="TableTwoText">
                  <div class="data">
                    0,0000
                  </div>
                </div>
                <div class="TableTwoText">
                  <div class="data">
                    0,0000
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Comentarios-->
          <div class="TextAreaCont d-flex justify-center align-center flex-column">
            <div class="TextAreaTitle">
              Offer Comments
            </div>
            <textarea v-model="comments" class="mt-2"></textarea>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { stateExpansiveManager } from '@/mixins/subscription.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Decimal from 'decimal.js';
export default {
  name: 'LossCurves',
  data() {
    return {
      detachment: 0,
      attachment: 0,
      comments: null,
      values: {
        diagonalDet: null,
        diagonalAtt: null,
        smallestRisksDet: null,
        smallestRisksAtt: null,
        smallHousesDet: null,
        smallHousesAtt: null,
        unMayorDet: null,
        unMayorAtt: null,
        dosMayorDet: null,
        dosMayorAtt: null,
        tresMayorDet: null,
        tresMayorAtt: null,
        greatDet: null,
        greatAtt: null,
        minDiagonal: null,
        minSmallestRisks: null,
        minSmallHouses: null,
        minUnMayor: null,
        minDosMayor: null,
        minTresMayor: null,
        minGreat: null,
      },
    };
  },
  mixins: [stateExpansiveManager],
  computed: {
    ...mapGetters([
      'premiumNon',
      'deductions',
      'layersArray',
      'layerSelect',
      'smallestRisks',
      'smallHouses',
      'unMayor',
      'dosMayor',
      'tresMayor',
      'great',
      'calculatePML',
    ]),
  },
  created() {
    this.calculeData();
  },
  methods: {
    ...mapMutations(['resetCalculatePML']),
    calculeData() {
      if (this.layerSelect) {
        this.detachment = new Decimal(this.layerSelect.limit).add(new Decimal(this.layerSelect.excess));
        this.attachment = new Decimal(this.layerSelect.excess);
        this.resetCalculatePML();
        for (var i = 0; i <= 100; i++) {
          this.calculatePML.push((new Decimal(this.premiumNon.pml) / 100) * i);
        }
        var indexAllDet = this.calculatePML.findIndex((element) => element >= this.detachment);
        var indexAllAtt = this.calculatePML.findIndex((element) => element >= this.attachment);

        this.values.diagonalDet = indexAllDet;
        this.values.diagonalAtt = indexAllAtt;
        this.values.smallestRisksDet = new Decimal(this.smallestRisks[indexAllDet]);
        this.values.smallestRisksAtt = new Decimal(this.smallestRisks[indexAllAtt]);
        this.values.smallHousesDet = new Decimal(this.smallHouses[indexAllDet]);
        this.values.smallHousesAtt = new Decimal(this.smallHouses[indexAllAtt]);
        this.values.unMayorDet = new Decimal(this.unMayor[indexAllDet]);
        this.values.unMayorAtt = new Decimal(this.unMayor[indexAllAtt]);
        this.values.dosMayorDet = new Decimal(this.dosMayor[indexAllDet]);
        this.values.dosMayorAtt = new Decimal(this.dosMayor[indexAllAtt]);
        this.values.tresMayorDet = new Decimal(this.tresMayor[indexAllDet]);
        this.values.tresMayorAtt = new Decimal(this.tresMayor[indexAllAtt]);
        this.values.greatDet = new Decimal(this.great[indexAllDet]);
        this.values.greatAtt = new Decimal(this.great[indexAllAtt]);
        this.values.minDiagonal = this.values.diagonalDet - this.values.diagonalAtt;
        this.values.minSmallestRisks = this.values.smallestRisksDet.minus(this.values.smallestRisksAtt);
        this.values.minSmallHouses = this.values.smallHousesDet.minus(this.values.smallHousesAtt);
        this.values.minUnMayor = this.values.unMayorDet.minus(this.values.unMayorAtt);
        this.values.minDosMayor = this.values.dosMayorDet.minus(this.values.dosMayorAtt);
        this.values.minTresMayor = this.values.tresMayorDet.minus(this.values.tresMayorAtt);
        this.values.minGreat = this.values.greatDet.minus(this.values.greatAtt);
      }
    },
  },
  watch: {
    'layerSelect.limit': function() {
      this.calculeData();
    },
    'layerSelect.excess': function() {
      this.calculeData();
    },
    'deductions.sum': function() {
      this.calculeData();
    },
    'premiumNon.pml': function() {
      this.calculeData();
    },
  },
};
</script>
<style lang="less" scoped>
@import '~@/assets/style/AccordionStyle.less'; //
.ExpandMiniTitle {
  position: absolute;
  width: 100%;
  left: 24px;
  color: #003d6d !important;
  font-weight: 600 !important;
  height: 100%;
  top: 0;
  .iconExpand {
    background: #003d6d;
    color: white !important;
    margin-right: 15px;
  }
}
.ExpansionLineTop {
  background: #d2deed;
}
.ExpandContent {
  justify-content: center !important;
}
//TABLA
.TableContent {
  width: 85%;
  height: 400px;
  //CABEZA DE LA TABLE
  .TableHead {
    width: 100%;
    height: 16%;

    .TableHeadSn {
      width: 81%;
      height: 100%;

      //TITULOS DE LA PARTE SUPERIOR
      .TableHeadTitle {
        width: 14.28%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        font-weight: bold;
        color: #547fa9;
      }
    }
  }

  //CUERPO DE LA TABLA
  .TableBody {
    width: 100%;
    height: 84%;
    align-content: center;
    align-items: center;

    //PARTE IZQUIERDA DE LA TABLA
    .TableSideCont {
      width: 19%;
      height: 100%;

      //TITULOS DE LA PARTE IZQUIERDA
      .SideLeftTitle {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .text {
          font-size: 16px;
          font-weight: 400;
          text-align: right;
          color: #547fa9;
          b {
            color: inherit;
          }
        }
      }
    }

    //VALORES DE LA TABLA
    .TableValuesCont {
      width: calc(81% - 1px);
      height: 100%;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;

      //FILAS DE LA TABLA
      .TableRowsCont {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: space-between;

        .font-weight-bold {
          font-weight: 700 !important;
        }
        .TableRow {
          width: 14.28%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;

          .data {
            border-bottom: solid 1px black;
          }
        }
      }
    }
  }
}

//TABLA DE DOS COLUMNAS
.TableTwoColumns {
  width: 85%;
  height: 80px;

  //CONTENEDOR PADRE
  .TableTwoSideCont {
    width: 19%;
    height: 100%;

    //TITULOS DE LA PARTE IZQUIERDA
    .SideLeftTwo {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: bold;
      color: #547fa9;
    }
  }

  //CONTENEDOR PADRE DE LOS VALORES DE LA TABLA
  .TableTwoValuesCont {
    width: 81%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    //FILAS DE LA TABLA
    .TableTwoRowsCont {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: space-between;

      //VALORES DE LA TABLA
      .TableTwoText {
        width: 14.28%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        .data {
          border-bottom: solid 1px black;
        }
      }
    }
  }
}

//AREA DE TEXTOS
.TextAreaCont {
  width: 100%;
  height: auto;

  .TextAreaTitle {
    width: 100%;
    font-size: 14px;
    color: #547fa9;
  }

  textarea {
    width: 100%;
    height: 90px;
    border: solid 1px #d2deed;
    border-radius: 15px;
    resize: none;
  }
}

//FONDOS GRISES PARA LA TABLA
.bgTable {
  background: #edf2f8 !important;
}
</style>
