<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <!--TITULO DEL ACORDEON-->
      <v-expansion-panel-header @click="changeSubExpansion" expand-icon="">
        <template v-slot:actions>
          <div
            class="
          ExpandMiniTitle
          d-flex justify-start align-center
          "
          >
            <div class="subIconCont d-flex justify-start align-center">
              <v-icon class="iconExpand">
                {{ iconSubExpansion }}
              </v-icon>
            </div>
            Layers
          </div>
        </template>
      </v-expansion-panel-header>
      <!--CONTENIDO DEL ACORDEON-->
      <v-expansion-panel-content>
        <div class="ExpandContent">
          <!--CONTENEDOR DE LAYERS-->
          <div class="LayerCont d-flex justify-center align-content-start align-start flex-wrap">
            <!--VISTA DE UN LAYER-->

            <div class="LayerContent" v-for="(item, index) in layersArray" :key="index">
              <div class="LayerHeader d-flex justify-start align-start align-content-start">
                <!--BOTON ELIMINAR LAYER-->
                <div class="CloseIcon d-flex justify-start align-content-center">
                  <v-btn icon @click="delLayer(index)">
                    <v-icon>
                      mdi-close-circle
                    </v-icon>
                  </v-btn>
                </div>

                <!--NUMERO DE LAYER-->
                <div class="HeaderInput">
                  <div class="LayerNumber d-flex align-center">
                    Layer 1
                  </div>
                </div>
              </div>

              <div class="SelectLayerCont d-flex justify-end align-center">
                Select layer
                <div class="iconCont" @click="setActiveLayer(item)">
                  <v-icon>
                    mdi-checkbox-marked-outline
                  </v-icon>
                </div>
              </div>

              <!--INPUTS-->
              <div class="LayerInputsCont d-flex justify-space-between ">
                <div class="InputCont">
                  <v-text-field v-model="item.limit" label="Limit:"></v-text-field>
                </div>

                <div class="InputCont">
                  <v-text-field v-model="item.excess" label="Excess:"></v-text-field>
                </div>

                <div class="InputCont">
                  <v-text-field v-model="deductions.sum" suffix="%" label="Deductions:"></v-text-field>
                </div>
              </div>

              <!--INFO-->
              <div class="InfoCont d-flex flex-wrap justify-space-between pa-3">
                <div class="InfoLine">
                  <span> SLU Share:</span>
                  <div class="InputCont"><v-text-field suffix="%" v-model="item.sluShare"></v-text-field></div>
                </div>
                <div class="InfoLine">
                  <span> SLU Limit: </span>
                  <div class="InputCont"><v-text-field suffix="%" v-model="item.sluLimit"></v-text-field></div>
                </div>

                <div class="InfoLine">
                  <span>Premium: </span>
                  <div class="InputCont"><v-text-field v-model="item.Premium"></v-text-field></div>
                </div>
                <div class="InfoLine">
                  <span> SLU Premium:</span>
                  <div class="InputCont"><v-text-field v-model="item.sluPremium"></v-text-field></div>
                </div>
              </div>
            </div>
          </div>

          <!--BOTON AÑADIR LAYER-->
          <div class="layerButtonCont d-flex justify-start align-center">
            <v-btn text rounded class="btn" @click="addLayer()">
              <v-icon class="mr-3">
                mdi-plus-circle
              </v-icon>
              Add New Layer
            </v-btn>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { stateExpansiveManager } from '@/mixins/subscription.js';
import Layer from '@/components/subscription/quotation/Layer';
import AddLayerButton from '@/components/subscription/quotation/AddLayerButton';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'layers',
  mixins: [stateExpansiveManager],
  components: {
    Layer,
    AddLayerButton,
  },
  data() {
    return {
      //INPUTS
      deduction: 100,
      detachment: null,
      attachment: null,
      //INFO
      share: '10%',
      limit: '10%',
      premium: '1,000,000.00',
      sluPremim: '1,000,000.00',
    };
  },
  computed: {
    ...mapGetters(['tivNon', 'deductions', 'layersArray', 'layerSelect']),
  },
  async mounted() {
    await this.getQuotationInformation();
    this.deduction = this.deductions.sum;
  },
  watch: {
    'deductions.sum': async function(newVal) {
      //this.$forceUpdate();
      console.log('cambio', newVal);
      this.setDeduction();
    },
  },
  methods: {
    ...mapMutations(['setStatelayerSelect']),
    ...mapActions(['getQuotationInformation']),
    addLayer() {
      var idLayer = this.layersArray.length;
      this.layersArray.push({ id: idLayer + 1, layer: '', limit: null, excess: null, deductions: null, sluShare: null, premium: 0 });
    },
    delLayer(index) {
      this.layersArray.splice(index, 1);
    },
    setActiveLayer(item) {
      item.deductions = this.deduction;
      this.setStatelayerSelect(item);
      this.setDeduction;
    },
    setDeduction() {
      console.log('cambio', this.deductions.sum);
      this.deduction = this.deductions.sum;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/AccordionStyle.less';
.ExpandMiniTitle {
  position: absolute;
  width: 100%;
  left: 24px;
  height: 100%;
  top: 0;
  color: #003d6d !important;
  font-weight: 600 !important;
  .iconExpand {
    background: #003d6d;
    color: white !important;
    margin-right: 15px;
  }
}

//LAYERS
.LayerCont {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 15px;

  //CONTENIDO
  .LayerContent {
    width: 60%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    //NUMERO DE LAYER
    .LayerHeader {
      width: 100%;
      height: 40px;

      //ICONO CERRAR LAYER
      .CloseIcon {
        margin-left: -45px;
        width: 45px;
        height: 45px;
        cursor: pointer;
        i {
          color: var(--secondaryColor);
          font-size: 27px;
        }
      }

      .HeaderInput {
        width: 25%;
        height: 100%;

        .LayerNumber {
          width: 100%;
          height: 30px;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    //SELECT LAYER
    .SelectLayerCont {
      width: 100%;
      height: 50px;
      color: grey;
      font-size: 16px;

      .iconCont {
        width: 37px;
        height: 37px;
        background: grey;
        border-radius: 37px;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #a8bfd9;
        i {
          font-size: 23px;
          color: #003d6d;
        }
      }
    }

    //INPUTS
    .LayerInputsCont {
      width: 100%;
      height: 120px;
      align-items: center;

      .InputCont {
        width: 30%;
      }
    }

    //INFO
    .InfoCont {
      width: 100%;
      height: 130px;
      border: solid 1px #d2deed;
      align-content: space-between;
      .InfoLine {
        width: 50%;
        height: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 700;
        font-size: 16px;

        span {
          color: #547fa9;
          margin-right: 5px;
        }

        .InputCont {
          width: 60%;
        }
      }
    }
  }
}

//BOTON AÑADIR LAYER
.layerButtonCont {
  width: 100%;
  height: 60px;
  margin-left: -24px;
  .btn {
    text-transform: none !important;
    color: #003d6d;
    font-weight: 500 !important;
    letter-spacing: normal;
  }
}
</style>
