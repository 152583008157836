<template>
  <div class="ExchangeCalcCont d-flex justify-center flex-wrap align-center">
    <div
      class="
        ExchangeCont
        pa-3
        d-flex
        justify-start
        align-content-start
        flex-wrap
      "
    >
      <!--TITULO-->
      <div
        class="
          TitleCont
          d-flex
          justify-center
          align-content-center align-center
        "
      >
        <h5>Total insured value - original currency</h5>
      </div>

      <!--INPUTS-->
      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Property Damage: </span>

        <div class="InputContainer">
          <!-- setStateTIV('propertyDamage', this);
          checkInsuredColumn('propertyDamage'); -->
          <!-- <v-text-field class="ml-3" v-model="tivNon.propertyDamage" type="number" prefix="$" @blur="calculeUSD()"></v-text-field> -->
          <currency-input
            v-model="tivNon.propertyDamage"
            :options="currencyOptions"
            @blur="
              calculeUSD();
              setStateTIVNON('propertyDamage', this);
              checkInsuredColumn('propertyDamage');
            "
            :suffix="quotation.currency"
          />
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Business Interruption </span>

        <div class="InputContainer">
          <!-- <v-text-field class="ml-3" v-model="tivNon.businessInterruption" type="number" prefix="$" @blur="calculeUSD()"></v-text-field> -->
          <currency-input
            v-model="tivNon.businessInterruption"
            :options="currencyOptions"
            @blur="
              calculeUSD();
              setStateTIVNON('businessInterruption', this);
              checkInsuredColumn('businessInterruption');
            "
            :suffix="quotation.currency"
          />
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Stocks: </span>

        <div class="InputContainer">
          <!-- <v-text-field class="ml-3" v-model="tivNon.stock" type="number" prefix="$" @blur="calculeUSD()"></v-text-field> -->
          <currency-input
            v-model="tivNon.stock"
            :options="currencyOptions"
            @blur="
              calculeUSD();
              setStateTIVNON('stock', this);
              checkInsuredColumn('stock');
            "
            :suffix="quotation.currency"
          />
        </div>
      </div>

      <!--LINEA DIVISORIA-->
      <div class="ExpansionLineTop mt-2"></div>

      <!--TOTAL-->
      <div class="InputContent d-flex justify-center align-center">
        <div class="Total">
          Total:
          <b> {{ tivNon.total }} </b>
        </div>
      </div>

      <!--LINEA DIVISORIA-->
      <div class="ExpansionLineTop"></div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> PML: </span>

        <div class="InputContainer">
          <!-- <v-text-field class="ml-3" v-model="premiumNon.pml" prefix="$"></v-text-field> -->
          <currency-input
            v-model="premiumNon.pml"
            :options="currencyOptions"
            @blur="
              calculeUSD();
              setStatePremiumNON('pml', this);
              checkPMLColumn('pml');
            "
            :suffix="quotation.currency"
            @change="value = $event"
          />
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Rate: </span>

        <div class="InputContainer">
          <v-text-field
            class="ml-3"
            type="number"
            v-model="premiumNon.rate"
            suffix="%"
            @blur="
              sumDatapremiumOriginal();
              setStatePremiumNON('rate', this);
              checkPMLColumn('rate');
            "
            :error-messages="quotationValids('premiumNon', 'rate')"
          ></v-text-field>
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput bold"> premiumNon: </span>

        <div class="InputContainer">
          <b class="ml-3"> {{ premiumNon.totalPremium }} </b>
        </div>
      </div>
    </div>

    <div
      class="
        ExchangeCont
        pa-3
        d-flex
        justify-start
        align-content-start
        flex-wrap
      "
    >
      <!--TITULO-->
      <div
        class="
          TitleCont
          d-flex
          justify-center
          align-content-center align-center
        "
      >
        <h5>Total Insurable value - USD</h5>
      </div>

      <!--INPUTS-->
      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Property Damage: </span>

        <div class="InputContainer">
          <currency-input
            :value="tivNon.propertyDamageUSD"
            :options="currencyOptions"
            @blur="
              calculeUSD();
              setStateTIVNON('propertyDamageUSD', this);
              checkInsuredColumn('propertyDamageUSD');
            "
            suffix="USD"
            @change="value = $event"
          />
          <!-- <v-text-field
            class="ml-3"
            v-model="tivNon.propertyDamageUSD"
            type="number"
            prefix="$"
            @blur="calculeUSD()"
          ></v-text-field> -->
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Business Interruption </span>

        <div class="InputContainer">
          <currency-input
            :value="tivNon.businessInterruptionUSD"
            :options="currencyOptions"
            @blur="
              calculeUSD();
              setStateTIVNON('businessInterruptionUSD', this);
              checkInsuredColumn('businessInterruptionUSD');
            "
            suffix="USD"
            @change="value = $event"
          />
          <!-- <v-text-field
            class="ml-3"
            v-model="tivNon.businessInterruptionUSD"
            type="number"
            prefix="$"
            @blur="calculeUSD()"
          ></v-text-field> -->
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Stocks: </span>

        <div class="InputContainer">
          <currency-input
            :value="tivNon.stockUSD"
            :options="currencyOptions"
            @blur="
              calculeUSD();
              setStateTIVNON('stockUSD', this);
              checkInsuredColumn('stockUSD');
            "
            suffix="USD"
            @change="value = $event"
          />
          <!-- <v-text-field
            class="ml-3"
            v-model="tivNon.stockUSD"
            type="number"
            prefix="$"
            @blur="calculeUSD()"
          ></v-text-field> -->
        </div>
      </div>
      <!--LINEA DIVISORIA-->
      <div class="ExpansionLineTop mt-2"></div>

      <!--TOTAL-->
      <div class="InputContent d-flex justify-center align-center">
        <div class="Total">
          Total:
          <b> {{ tivNon.totalUSD }} </b>
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> PML: </span>

        <div class="InputContainer">
          <currency-input
            v-model="premiumNon.pmlUSD"
            :options="currencyOptions"
            @blur="
              calculeUSD();
              setStatePremiumNON('pmlUSD', this);
              checkPMLColumn('pmlUSD');
            "
            suffix="USD"
            @change="value = $event"
          />
          <!-- <v-text-field
            class="ml-3"
            v-model="premiumNon.pmlUSD"
            prefix="$"
          ></v-text-field> -->
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Rate: </span>

        <div class="InputContainer">
          <v-text-field
            class="ml-3"
            type="number"
            v-model="premiumNon.rateUSD"
            suffix="%"
            @blur="
              sumDatapremiumUSD();
              setStatePremiumNON('rateUSD', this);
              checkPMLColumn('rateUSD');
            "
            @change="value = $event"
            :error-messages="quotationValids('premiumNon', 'rateUSD')"
          ></v-text-field>
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput bold"> premiumNon: </span>

        <div class="InputContainer">
          <b class="ml-3"> {{ premiumNon.totalPremiumPremiumUSD }} </b>
        </div>
      </div>
    </div>

    <!--LINEA DIVISORIA-->
    <div class="ExpansionLineTop blueLight mt-2"></div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
/* validations */
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { DigitsAndDecimals, Percentage } from '@/constants/validations';
import { formValidations } from '@/mixins/formValidations';
/* components */
import ButtonActiveModalRate from '@/components/Rate/ButtonActiveModalRate.vue';
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput.vue';

export default {
  name: 'ExchangeCalculator',
  mixins: [formValidations, validationMixin],
  data() {
    return {
      currencyOptions: {
        currency: 'MXN',
        currencyDisplay: 'narrowSymbol',
        locale: 'en-US',
      },
    };
  },
  components: {
    CurrencyInput,
  },
  computed: {
    ...mapGetters(['quotation', 'tivNon', 'premiumNon']),
  },
  watch: {
    'quotation.exchangeRate': function() {
      this.calculeUSD();
      if (this.premiumNon.rate || this.premiumNon.rateUSD) {
        this.sumDatapremiumOriginal();
        this.sumDatapremiumUSD();
        this.calculeUSDpml();
      }
    },
    'tivNon.propertyDamage': function() {
      this.calculeUSD();
      if (this.premiumNon.rate || this.premiumNon.rateUSD) {
        this.sumDatapremiumOriginal();
        this.sumDatapremiumUSD();
      }
    },
    'tivNon.businessInterruption': function() {
      this.calculeUSD();
      if (this.premiumNon.rate || this.premiumNon.rateUSD) {
        this.sumDatapremiumOriginal();
        this.sumDatapremiumUSD();
      }
    },
    'tivNon.stock': function() {
      this.calculeUSD();
      if (this.premiumNon.rate || this.premiumNon.rateUSD) {
        this.sumDatapremiumOriginal();
        this.sumDatapremiumUSD();
      }
    },
    'premiumNon.rate': function() {
      this.calculeUSD();
      if (this.premiumNon.rate || this.premiumNon.rateUSD) {
        this.sumDatapremiumOriginal();
        this.sumDatapremiumUSD();
      }
    },
    'tivNon.total': async function(v) {
      this.setStateTIVNON('total', v);
      await this.checkInsuredColumn('total');
    },
    'tivNon.totalUSD': async function(v) {
      this.setStateTIVNON('totalUSD', v);
      await this.checkInsuredColumn('totalUSD');
    },
    'premiumNon.totalPremium': async function(v) {
      this.setStatePremiumNON('totalPremium', v);
      await this.checkPMLColumn('totalPremium');
    },
    'premiumNon.totalPremiumUSD': async function(v) {
      this.setStatePremiumNON('totalPremiumUSD', v);
      await this.checkPMLColumn('totalPremiumUSD');
    },
  },
  validations: {
    tivNon: {
      propertyDamage: { required },
      businessInterruption: { required },
      stock: { required },
      total: { required },
      totalUSD: { required },
      porcentaje: { required },
      propertyDamageUSD: { required },
      businessInterruptionUSD: { required },
      stockUSD: { required },
    },
    premiumNon: {
      rate: { required, DigitsAndDecimals, Percentage },
      rateUSD: { required, DigitsAndDecimals, Percentage },
      pml: { required },
      pmlUSD: { required },
      totalPremium: { required },
      totalPremiumUSD: { required },
    },
  },
  methods: {
    ...mapMutations(['setStateTIVNON', 'setStatePremiumNON']),
    ...mapActions(['saveQuotationColumn']),
    calculeUSD() {
      if (this.quotation.exchangeRate) {
        this.tivNon.propertyDamageUSD = this.tivNon.propertyDamage / this.quotation.exchangeRate;
        this.tivNon.businessInterruptionUSD = this.tivNon.businessInterruption / this.quotation.exchangeRate;
        this.tivNon.stockUSD = this.tivNon.stock / this.quotation.exchangeRate;
        this.sumData();
        this.calculeUSDpml();
      }
    },
    calculeUSDpml() {
      if (this.quotation.exchangeRate) this.premiumNon.pmlUSD = this.premiumNon.pml / this.quotation.exchangeRate;
    },
    sumDatapremiumOriginal() {
      this.premiumNon.rateUSD = this.premiumNon.rate;
      this.sumDatapremiumUSD();
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      this.premiumNon.totalPremium =
        (this.tivNon.propertyDamage * this.premiumNon.rate) / 1000 +
        (this.tivNon.businessInterruption * this.premiumNon.rate) / 1000 +
        (this.tivNon.stock * this.premiumNon.rate) / 1000;

      this.premiumNon.totalPremium = formatter.format(this.premiumNon.totalPremium);
    },
    sumDatapremiumUSD() {
      if (this.premiumNon.rateUSD) {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        this.premiumNon.totalPremiumPremiumUSD =
          (this.tivNon.propertyDamageUSD * this.premiumNon.rateUSD) / 1000 +
          (this.tivNon.businessInterruptionUSD * this.premiumNon.rateUSD) / 1000 +
          (this.tivNon.stockUSD * this.premiumNon.rateUSD) / 1000;

        this.premiumNon.totalPremiumPremiumUSD = formatter.format(this.premiumNon.totalPremiumPremiumUSD);
      }
    },
    sumData() {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      this.tivNon.total =
        (!this.tivNon.propertyDamage ? 0 : parseFloat(this.tivNon.propertyDamage)) +
        (!this.tivNon.businessInterruption ? 0 : parseFloat(this.tivNon.businessInterruption)) +
        (!this.tivNon.stock ? 0 : parseFloat(this.tivNon.stock));
      this.tivNon.totalUSD =
        (!this.tivNon.propertyDamageUSD ? 0 : parseFloat(this.tivNon.propertyDamageUSD)) +
        (!this.tivNon.businessInterruptionUSD ? 0 : parseFloat(this.tivNon.businessInterruptionUSD)) +
        (!this.tivNon.stockUSD ? 0 : parseFloat(this.tivNon.stockUSD));

      this.tivNon.total = formatter.format(this.tivNon.total);
      this.tivNon.totalUSD = formatter.format(this.tivNon.totalUSD);
    },
    async checkInsuredColumn(column) {
      this.$v.tivNon[column].$touch();
      if (this.$v.tivNon[column].$invalid) return;
      await this.saveQuotationColumn({
        table: 'insured',
        parent: 'tivNon',
        column,
      });
    },
    async checkPMLColumn(column) {
      this.$v.premiumNon[column].$touch();
      if (this.$v.premiumNon[column].$invalid) return;
      await this.saveQuotationColumn({
        table: 'insured',
        parent: 'premiumNon',
        column,
      });
    },
  },
};
</script>
<style lang="less" scoped>
@FontSizeLabel: 15px;
.ExchangeCalcCont {
  width: 100%;
  height: auto;
  position: relative;

  //Boton tarifar
  .TarifarBtn {
    position: absolute;
    right: 0;
    top: 0;
  }
  .ExchangeCont {
    width: 35%;
    height: auto;

    .ExpansionLineTop {
      background: #547fa9 !important;
    }

    //TITULO
    .TitleCont {
      width: 100%;
      height: 60px;
      h5 {
        font-size: 16px !important;
        text-transform: capitalize;
        color: black;
        font-weight: 600 !important;
        text-align: center;
      }
    }

    //INPUTS
    .InputContent {
      width: 100%;
      height: 50px;
      //LABEL
      .LabelInput {
        color: #547fa9;
        font-size: @FontSizeLabel;
      }
      .bold {
        font-weight: 600;
        font-size: @FontSizeLabel !important;
      }

      .InputContainer {
        width: 50%;

        b {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .Total {
      font-size: 19px;
      font-size: @FontSizeLabel;
      font-weight: 600;
      color: #547fa9 !important;
      b {
        color: black;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  //DIVISION VERTICAL
  .VerticalDiver {
    width: 1px;
    height: 490px;
    background: black;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}
.blueLight {
  background: #a8bfd9 !important;
}
.blueLight {
  background: #a8bfd9 !important;
}
@media (max-width: 640px) {
  .ExchangeCalcCont {
    .ExchangeCont {
      width: 100%;
      padding: 0 !important;
      //TITULO
      .TitleCont {
        h5 {
          font-size: 15px;
        }
      }

      //INPUTS
      .InputContent {
        .LabelInput {
          font-size: 14px;
          height: 60%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }

      //TOTAL
      .Total {
        font-size: 14px;
      }
    }
  }
}
</style>
