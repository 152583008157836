<template>
<div class="Cont d-flex justify-start align-center flex-wrap align-content-center mt-6 ">
	<!--TITULO-->
	<div class="TitleCont">
		<h5>
			ALOP Rate
		</h5>
	</div>
	<div class="
		Table
		d-flex
		justify-end
		align-content-start
		flex-wrap
		">
		<!--CABECERA DE LA TABLA-->
		<div class="Line">

			<div class="MiniRow Bold"/>
			<div class="Row Bold"/>
			<div class="Row Bold"/>

			<div class="Row Bold">
				Factor
			</div>
			<div class="Row Bold">
				Rate
			</div>
			<div class="Row Bold">
				Credit / <br>
				Surcharge
			</div>
			<div class="Row Bold">
				Reason
			</div>
			<div class="Row Bold">
				Final Rate
			</div>
		</div>

		<div class="Line">
			<div class="MiniRow Txt">
				Months
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-select>
				</v-select>
			</div>
			<div class="Row">
				<v-text-field
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
		</div>

		<!--SEPARADOR-->
		<div class="ExpansionLineTop mt-3 HideOnMovil"/>

	</div>
</div>
</template>
<script>
export default{
	name:'AlopRate',
	data(){
		return{
			FloodRate:null
		}
	}
}
</script>
<style lang="less" scoped>
/*
Estilos compartidos por los componentes
del tarifador, controlan:
	- Clase .flex()
	- Titulo general
	- Contenedor general
*/
@import '~@/assets/style/Subscription/Rate.less';
	/*
		Estilos únicos del componente
	*/
.Table{
	.flex();
	width: 100%;
	height: auto;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;

	.Line{
		.flex();
		width:100%;
		height: 50px;
		justify-content: space-between;


		.MiniRow{
			.flex();
			width: 8%;
			height: 50px;
			justify-content: flex-start;
		}
		.Row{
			.flex();
			width: 12.5%;
			height: 50px;
			justify-content: flex-start;
		}
		.Txt{
			color: #547FA9;
		}

		.Bold{font-weight: 600;}

	}
}


</style>