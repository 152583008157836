<template>
  <div class="AppMain d-flex flex-wrap">
    <!--MENU LATERAL IZQUIERDO-->
    <div class="LateralMenuContent HideOnMovil">
      <MenuGeneral />
    </div>

    <!--MENU PARA DISPOSITIVOS MOVILES-->
    <div class="SuperiorMenuContent ShowFlexOnMovil">
      <MenuGeneralMovil />
    </div>

    <!--CONTENEDOR GENERAL-->
    <div class="GeneralContent FullOnMovil pl-10 pr-10">
      <!--TITULO DE LA PÁGINA-->
      <TitlePage title="Subscription" />

      <!--BARRA DE NAVEGACIÓN DE SUBSCRIPTION-->
      <BarNav link1="" link2="lnkActive" />

      <!--POPORTIONAL / NON PROPORTIONAL -->
      <TypeProportional />
      
      <!--PASOS DE REGISTRO-->
      <Stepper
        step2="StepActive"
        stepMovil1=""
        stepMovil2="Quotation"
        stepMovil3=""
      />

      <div class="CopyAndDetailscont">
        <EditAccount :editAccount="false" accountName="Editing account" />
        <CopyAccount />
      </div>

      <QuoNonProp />

      <!--SLIP DOCUMENTS-->
      <SlipDocuments />

      <!--NOTAS-->
      <NotesComponent />

      <!--BOTONES PARA FINALIZAR-->
      <ButtonsQuotation />

      <!--MODAL TARIFAR-->
      <RateModalCarEar />

      <!--ESPACIO EN BLANCO-->
      <WhiteSpace></WhiteSpace>
    </div>
  </div>
</template>
<script>
import RateModalCarEar from '@/components/Rate/RateModalCarEar.vue';

import TitlePage from '@/components/TitlePage.vue';
import MenuGeneral from '@/components/Menu/MenuGeneral.vue';
import MenuGeneralMovil from '@/components/Menu/MenuGeneralMovil.vue';
import BarNav from '@/components/subscription/BarNav.vue';
import QuoNonProp from '@/components/subscription/quotation/QuoNonProp.vue';
import EditAccount from '@/components/subscription/EditAccount.vue';
import CopyAccount from '@/components/subscription/quotation/CopyAccount.vue';

import TypeProportional from '@/components/subscription/quotation/TypeProportional.vue';
import Stepper from '@/components/subscription/submission/Stepper';

import NotesComponent from '@/components/Notes/NotesComponent.vue';

import ExposureCurves from '@/components/subscription/quotation/ExposureCurves.vue';

import SlipDocuments from '@/components/subscription/submission/SlipDocuments.vue';
import ButtonsQuotation from '@/components/subscription/quotation/ButtonsQuotation';

import WhiteSpace from '@/components/WhiteSpace.vue';

//MIXINS
import { stateExpansiveManager } from '@/mixins/subscription.js';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'QuotationNonProportional',
  mixins: [stateExpansiveManager],
  components: {
    TitlePage,
    RateModalCarEar,
    MenuGeneral,
    MenuGeneralMovil,
    BarNav,
    TypeProportional,
    Stepper,
    QuoNonProp,
    CopyAccount,
    EditAccount,
    NotesComponent,
    ExposureCurves,
    SlipDocuments,
    ButtonsQuotation,
    WhiteSpace,
  },
  computed: {
    ...mapGetters(['subscription_id']),
  },
  async beforeMount() {
    /* set loaders */
    const lpa = 'loadingPanel';
    /* loaders to true */
    this[lpa] = !this[lpa];
    /* load data */
    await this.checkSubscriptionStored();
    /* load finished */
    this[lpa] = false;
  },
  methods: {
    ...mapActions(['checkSubscriptionStored']),
    startLoad() {
      this.loader = 'loading';
      const l = this.loader;
      this[l] = !this[l];
    },
    endLoad(l) {
      this[l] = false;
      this.loader = null;
    },
    tempReturn() {
      if (this.subscription_id)
        return `/subscription/${this.subscription_id}/submission`;
      return '/subscription/submission';
    },
  },
};
</script>
<style lang="less" scoped>
//CONTENEDOR DE QUOTATION
@import '~@/assets/style/AccordionStyle.less';
</style>
